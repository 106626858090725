<template>
  <v-container class="mb-12">
    <v-card
      v-for="(group, k) in groups"
      :key="k"
      tile
      subheader
      class="mt-5"
      elevation="0"
      :to="'/groups/timetablepergroup/' + group.id"
    >
      <v-card-title class="pb-1 pt-0">
        Groupe {{ group.name }}
        <v-spacer />

        <v-list-item-icon><v-icon>mdi-chevron-right</v-icon> </v-list-item-icon>
      </v-card-title>

      <v-card-text>
        <v-row v-for="(team, i) in group.teams" :key="i" class="ma-0">
          <span v-if="myTeam == team.id_equipe"
            >{{ team.name }} {{ team.id }}</span
          ><span v-else>{{ team.name }}</span>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Groups',
  data: () => ({
    categories: [],
    selectedCategory: 0,
    groups: [],
  }),
  async mounted() {
    this.myTeam = this.$store.getters.getTeamId
    this.$emit('set-title', 'Liste des groupes')
    axios.defaults.baseURL = 'https://sekulic2023.e-tournament.ch/api/'
    const response = await axios.get(
      'json.php?get=groups-teams&idc=' + this.$store.getters.getCategory
    )
    this.groups = response.data

    this.$emit('set-menu', {
      title: 'Groupes',
      link: '/',
      icon: 'mdi-close',
    })
  },
  create() {},
}
</script>

<style lang="scss">
.grouplink {
  text-decoration: none;
  color: #000 !important;
}
.btnlower {
  text-transform: none;
}
.v-card.v-card--link {
  border: 1px #eeeeee solid !important;
}
</style>
