<template>
  <v-container class="mb-12">
    <v-list>
      <v-list-item
        v-for="(team, k) in teams"
        :key="k"
        :to="'/teams/timetableperteam/' + team.id_groupe + '/' + team.id_equipe"
        :class="setColor(team.id_equipe)"
      >
        <v-list-item-content>
          <span style="line-height: 2em"> {{ team.club }} {{ team.no }} </span>
        </v-list-item-content>
        <v-list-item-icon
          ><v-icon right>mdi-chevron-right</v-icon></v-list-item-icon
        >
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Teams',
  data: () => ({
    categories: [],
    selectedCategory: 0,
    teams: [],
    team1: null,
    team2: null,
  }),
  methods: {
    setColor: function (team) {
      if (team == this.team1 || team == this.team2) {
        return 'yellow lighten-3'
      }
    },
  },
  async mounted() {
    this.$emit('set-menu', {
      title: 'Equipes',
      link: '/',
      icon: 'mdi-close',
    })

    this.team1 = this.$store.getters.getTeam1
    this.team2 = this.$store.getters.getTeam2

    axios.defaults.baseURL = 'https://sekulic2023.e-tournament.ch/api/'
    const response = await axios.get(
      'json.php?get=teams&idc=' + this.$store.getters.getCategory
    )
    this.teams = response.data
  },
}
</script>

<style lang="scss">
.grouplink {
  text-decoration: none;
  color: #000 !important;
}
.btnlower {
  text-transform: none;
}
.v-list-item {
  border-bottom: 1px solid #ddd;
}
</style>
